import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je ostrovní biogeografie?" />
    <h1>Co je ostrovní biogeografie?</h1>
    <p>Ostrovn&iacute; biogeografie je teorie, kter&aacute; vysvětluje dynamiku rostlinn&yacute;ch a živoči&scaron;n&yacute;ch druhů na ostrovech v z&aacute;vislosti na rozloze ostrova a jeho vzd&aacute;lenosti od pevniny.</p>
<p>Ostrovn&iacute; biota je totiž specifick&aacute;, s men&scaron;&iacute;m počtem druhů než pevnina, ale s vysokou m&iacute;ru <Link to="/biogeografie/areografie/typy-rozsireni/#endemity">endemismu</Link>. Li&scaron;&iacute; se v mnoha faktorech, vyskytuj&iacute; se zvl&aacute;&scaron;tn&iacute; morfologie a rozmanit&eacute; adaptace, rovněž je jin&eacute; druhov&eacute; složen&iacute; a např&iacute;klad druhy z&aacute;visl&eacute; na jin&yacute;ch druz&iacute;ch (epifyty či paraziti) se vyskytuj&iacute; m&eacute;ně.</p>
<hr />
<h2>Klasick&yacute; pohled ostrovn&iacute; biogeografie</h2>
<p>Podle klasick&eacute;ho pohledu ostrovn&iacute; biogeografie je počet druhů na ostrovech d&aacute;n dvěma hlavn&iacute;mi faktory:</p>
<ul>
<li><strong>velikost ostrova</strong> (velk&eacute; ostrovy maj&iacute; v&iacute;ce stanovi&scaron;ť a nik maj&iacute; v&iacute;ce druhů)</li>
<li><strong>izolovanost, vzd&aacute;lenost od pevniny</strong> (ostrovy d&aacute;le od pevniny je těž&scaron;&iacute; kolonizovat, m&eacute;ně druhů)</li>
</ul>
<p>Disturbance na ostrovech mohou sn&aacute;ze vyhubit někter&eacute; druhy, chyb&iacute; dosycov&aacute;n&iacute; populac&iacute; propagulemi z okol&iacute; a tak zde nemohou b&yacute;t trvale druhy se z&aacute;porn&yacute;m populačn&iacute;m růstem (sink populations).</p>
<p><Link to="/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy/">Křivka SAR</Link> zn&aacute;zorňuj&iacute;c&iacute; růst počtu druhů s velikost&iacute; plochy m&aacute; zde strměj&scaron;&iacute; růst než na pevnině, tzn. že zde s rostouc&iacute; plochou přib&yacute;vaj&iacute; druhy rychleji než na pevnin&aacute;ch.</p>
<p>V&yacute;razn&yacute; vztah izolace/druhov&aacute; bohatost je patrn&yacute; jen u vulkanick&yacute;ch ostrovů a atolů. Kontinent&aacute;ln&iacute; ostrovy jsou zpravidla m&eacute;ně izolovan&eacute; a obsahuj&iacute; pozůstatky původn&iacute; kontinent&aacute;ln&iacute; bioty, druhov&aacute; <Link to="/biogeografie/biodiverzita/">biodiverzita</Link> je na nich vy&scaron;&scaron;&iacute; a vliv izolovanosti je zde m&eacute;ně patrn&yacute;.</p>
<hr />
<h2>Rovnov&aacute;žn&aacute; teorie ostrovn&iacute; biogeografie</h2>
<p>MacArthur a Wilson (1963, 1967) definovali rovnov&aacute;žnou teorii ostrovn&iacute; biogeografie, kter&aacute; tvrd&iacute;, že v&yacute;sledn&yacute; počet druhů na ostrově (S) a m&iacute;ra jejich obměny (T, změna počtu druhů za jednotku času) jsou v&yacute;sledkem procesů imigrace a extinkce. Obě proměnn&eacute; lze vykreslit v grafu, v m&iacute;stě, kde se prot&iacute;naj&iacute;, se pak nach&aacute;z&iacute; rovnov&aacute;žn&yacute; stav.</p>
<p>Inspirac&iacute; bylo zničen&iacute; ostrova v&yacute;buchem sopky Krakatoa a n&aacute;sledn&eacute; vytvořen&iacute; ostrovů nov&yacute;ch, na nichž byla biota vyhubena. Počet pt&aacute;ků (avifauna) rychle rostl, ale pak se stabilizoval. Druhy se zde v&scaron;ak obměňovaly (species turnover), proch&aacute;zely sukces&iacute; a to zejm&eacute;na v reakci na zalesňov&aacute;n&iacute; ostrova.</p>
<p>Za hlavn&iacute; faktor m&iacute;ry extinkce je považov&aacute;na velikost ostrova, kdy men&scaron;&iacute; ostrov znamen&aacute; vy&scaron;&scaron;&iacute; extinkci, a za hlavn&iacute; faktor imigrace izolovanost, kdy ostrovy vzd&aacute;leněj&scaron;&iacute; od pevniny vykazuj&iacute; niž&scaron;&iacute; imigraci. Dle toho lze generalizovaně vyřknout tato pravidla, kter&aacute; jsou zn&aacute;zorněna na obr.#:</p>
<ul>
<li><strong>mal&yacute; ostrov bl&iacute;zko pevniny</strong> = počet druhů nadprůměrn&yacute;, nejvy&scaron;&scaron;&iacute; m&iacute;ra obměny druhů</li>
<li><strong>mal&yacute; ostrov daleko od pevniny</strong> = počet druhů nejniž&scaron;&iacute;, m&iacute;ra obměny podprůměrn&aacute;</li>
<li><strong>velk&yacute; ostrov bl&iacute;zko pevniny</strong> = počet druhů nejvy&scaron;&scaron;&iacute;, m&iacute;ra obměny nadprůměrn&aacute;</li>
<li><strong>velk&yacute; ostrov daleko od pevniny</strong> = počet druhů podprůměrn&yacute;, m&iacute;ra obměny nejniž&scaron;&iacute;</li>
</ul>
<h3>V&yacute;zkumy v souostrov&iacute; Krakatoa</h3>
<p>V&yacute;zkumy v souostrov&iacute; Krakatoa pouk&aacute;zaly na někter&eacute; ze siln&yacute;ch str&aacute;nek teorie, zvl&aacute;&scaron;ť na jej&iacute; důraz na dynamick&eacute; procesy. Uk&aacute;zalo se, že postupem času m&aacute; m&iacute;ra imigrace tendenci klesat se zvy&scaron;uj&iacute;c&iacute; se saturac&iacute; prostřed&iacute; druhy. Extinkce m&aacute; naopak tendenci stoupat v z&aacute;vislosti na prob&iacute;haj&iacute;c&iacute; sukcesi, při n&iacute;ž někter&eacute; druhy miz&iacute;. To podporuje domněnku, že existuje rovnov&aacute;žn&yacute; stav mezi těmito proměnn&yacute;mi.</p>
<p>M&iacute;ry imigrace a extinkce, respektive jejich průběhy postupem času, jsou v&scaron;ak taxonomicky specifick&eacute; a nejsou univerz&aacute;ln&iacute; např&iacute;č druhy. Nav&iacute;c, pro řadu taxonomick&yacute;ch skupin se n&aacute;růst počtu druhů postupně v&yacute;razně zpomalil pod předpoklad a někter&eacute; se rovnov&aacute;žn&eacute;mu bodu d&aacute;le nebl&iacute;ž&iacute;. Zd&aacute; se, že i samotn&aacute; schopnost &scaron;&iacute;řen&iacute; druhů je v&yacute;znamnou proměnnou.</p>
<h3>Experiment&aacute;ln&iacute; testov&aacute;n&iacute; teorie</h3>
<p>Simberloff a Wilson provedli v roce 1969 experiment&aacute;ln&iacute; defaunaci mangrovov&yacute;ch ostrovů ve Floridsk&eacute;m z&aacute;livu. Do&scaron;lo k odstraněn&iacute; členovců pomoc&iacute; metylbromidy a byl proveden z&aacute;znam rekolonizace.</p>
<p>Rekolonizace ostrůvku proběhla velmi rychle, zhruba za rok již byla ustanovena rovnov&aacute;ha. Na ostrovech se usadil stejn&yacute; počet druhů jako před defaunac&iacute;, a to podle jejich velikosti a izolovanosti.</p>
<h3>Modifikace rovnov&aacute;žn&eacute; teorie ostrovn&iacute; biogeografie</h3>
<p>Křivky počtu druhů (S) a jejich obměny (T) nejsou v re&aacute;ln&yacute;ch situac&iacute;ch hladk&eacute; a jsou modifikov&aacute;ny různ&yacute;mi faktory. Prvn&iacute;m jsou disturbance dan&eacute;ho ostrovn&iacute;ho společenstva. Pokud k v&yacute;razn&yacute;m disturbanc&iacute;m (hurik&aacute;ny, erupce aj.) doch&aacute;z&iacute; př&iacute;li&scaron; často, nelze prakticky dos&aacute;hnout rovnov&aacute;žn&eacute;ho stavu.</p>
<h4>Z&aacute;kladn&iacute; faktory ovlivňuj&iacute;c&iacute; počet druhů</h4>
<p>V&yacute;voj počtu druhů při postupn&eacute;m osidlov&aacute;n&iacute; ostrova m&aacute; podoba kol&iacute;sav&eacute; křivky se vzestupnou tendenc&iacute; směřuj&iacute;c&iacute; k rovnov&aacute;žn&eacute;mu stavu, jej&iacute;ž průběh ovlivňuj&iacute; zejm&eacute;na:</p>
<ul>
<li><strong>mezidruhov&eacute; interakce</strong> (způsob&iacute; drobn&yacute; pokles po poč&aacute;tečn&iacute;m prudk&eacute;m růstu, kdy nebyla př&iacute;tomn&aacute; kompetice či predace)</li>
<li><strong>sukcese</strong> (někter&eacute; druhy z&aacute;visl&eacute; na pion&yacute;rsk&yacute;ch druz&iacute;ch vymiz&iacute;, naplněn&iacute; voln&yacute;ch nik zpomal&iacute; kolonizaci)</li>
<li><strong>speciace</strong> (evolučn&iacute; v&yacute;voj nov&yacute;ch druhů, vznik endemitů, zejm&eacute;na na velk&yacute;ch a vzd&aacute;len&yacute;ch ostrovech)</li>
</ul>
<h4>Rescue effect</h4>
<p>Rescue efekt způsobuje niž&scaron;&iacute; m&iacute;ru obměny na m&eacute;ně izolovan&yacute;ch ostrovech. Bl&iacute;zkost populac&iacute; dan&eacute;ho druhu totiž snižuje v důsledku neust&aacute;l&eacute;ho př&iacute;livu imigrantů riziko extinkce a dan&eacute;m ostrově.</p>
<h4>The target area effect</h4>
<p>The target area effect způsobuje, že m&iacute;ra obměny druhů může b&yacute;t u velk&yacute;ch ostrovů vy&scaron;&scaron;&iacute;, než by predikoval model. Opačně to plat&iacute; pro mal&eacute; ostrovy. Imigrace je totiž ovlivněna velikost&iacute; ostrova, č&iacute;m vět&scaron;&iacute; ostrov je, t&iacute;m sn&aacute;ze je detekovatelněj&scaron;&iacute; aktivn&iacute;mi migranty. Z&aacute;roveň se zde s vět&scaron;&iacute; pravděpodobnost&iacute; zachyt&iacute; organismy &scaron;&iacute;řen&eacute; pasivně.</p>
<h4>The small island effect</h4>
<p>The small island effect ovlivňuje <Link to="/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy/">SAR</Link>, tedy z&aacute;vislost počtu druhů na velikosti plochy ostrova. Ta je zřejm&aacute; až od jist&eacute; minim&aacute;ln&iacute; plochy (obvykle lehce nad 3&nbsp;akry).</p>
<hr />
<h2>Aplikace teorie ostrovn&iacute; biogeografie</h2>
<p>Aplikace teorie ostrovn&iacute; biogeografie je v na&scaron;em prostřed&iacute; běžn&aacute; na tzv. izolovan&aacute; stanovi&scaron;tě v moři okoln&iacute; krajiny (např. jezera, prameni&scaron;tě, ra&scaron;elini&scaron;tě aj.). M&aacute; pak velmi v&yacute;znamn&yacute; přesah do ochran&aacute;řsk&eacute; biologie a navrhov&aacute;n&iacute; chr&aacute;něn&yacute;ch &uacute;zem&iacute;.</p>
<p>V ochran&aacute;řstv&iacute; je běžn&aacute; tzv. SLOSS ot&aacute;zka (zkratka pro anglick&eacute; <em>Single Layers or Several Small?</em>) ře&scaron;&iacute;c&iacute; dilema, zda raději chr&aacute;nit jedno velk&eacute; &uacute;zem&iacute; či několik mal&yacute;ch. Při chr&aacute;něn&iacute; populace vz&aacute;cn&eacute;ho druhu je lep&scaron;&iacute; jedna velk&aacute; rezervace.</p>
<p>Pro zachov&aacute;n&iacute; vět&scaron;&iacute; diverzity stanovi&scaron;ť a v&iacute;ce druhů je v&scaron;ak lep&scaron;&iacute; m&iacute;t několik mal&yacute;ch chr&aacute;něn&yacute;ch rezervac&iacute;. Ty by v&scaron;ak měly b&yacute;t propojeny koridory, aby byly nevyhnuteln&eacute; extinkce druhů vyv&aacute;ženy nov&yacute;mi imigracemi. Z tohoto principu vych&aacute;z&iacute; např. &Uacute;SES.</p>
<hr />
<h2>Samostatn&eacute; podt&eacute;ma ostrovn&iacute; biogeografie</h2>
<p>Pro zachov&aacute;n&iacute; přehlednosti bylo z těchto studijn&iacute;ch pozn&aacute;mek o ostrovn&iacute; biogeografii vyčleněno samostatn&eacute; t&eacute;ma, a to:</p>
<div className="od-sm">
<hr />
<h3><Link to="/biogeografie/ostrovni/zvlastnosti-bioty/">Zvl&aacute;&scaron;tnosti ostrovn&iacute; bioty</Link></h3>
<p>Ostrovn&iacute; biota je charakteristick&aacute; nejen odli&scaron;nou strukturou, ale i množstv&iacute;m endemitů, kter&eacute; maj&iacute; často divok&eacute; adaptace. V rychl&eacute;m soupisu můžete nastudovat ty nejz&aacute;kladněj&scaron;&iacute; a nejběžněj&scaron;&iacute; z nich, např&iacute;klad gigantismus či nanismus ř&iacute;d&iacute;c&iacute; se tzv. ostrovn&iacute;m pravidlem.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Ostrovní biogeografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/biodiverzita/lokalni-druhove-bohatstvi/"><button className="inv">&larr; Lokální druhová bohatost</button></Link>
    <Link to="/biogeografie/ostrovni/zvlastnosti-bioty/"><button className="inv">Zvláštnosti ostrovní bioty &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
